<template>
  <div
    style="border: 1px solid #e2e2e2"
    class="bg-[#FFFFFF] rounded-[8px]"
  >
    <menu
      style="border-bottom: 1px solid #F4F4F4;"
      class="pt-[1rem] px-[1.5rem] d-flex gap-[8px] align-items-center"
    >
      <button
        v-for="(content, idx) in komshipTabContents"
        :key="idx"
        class="outline-none d-flex align-items-center gap-[3px] p-[8px] text-[14px]"
        :class="tabIndex == idx ? 'border-bottom' : 'border-0'"
        :style="{ color: tabIndex == idx ? '#F95031' : '#828282', borderColor: tabIndex == idx ? '#F95031 !important' : '' }"
        @click="changeTab(idx)"
      >
        <Icon
          :name="content"
          :color="tabIndex == idx ? '#F95031' : '#828282'"
        />
        <span>{{ content }}</span>
      </button>
    </menu>
    <div class="py-[1rem] px-[1.5rem] d-flex justify-between gap-[1rem]">
      <div class="d-flex search-drop">
        <div class="d-flex align-items-center rounded-[8px] overflow-hidden komsearch">
          <Icon
            name="Search"
            class="ml-[12px]"
          />
          <b-form-input
            id="search-komship"
            v-model="filter.keyword"
            type="search"
            :placeholder="placeholders[tabIndex]"
            debounce="500"
            class="border-0 shadow-none"
            size="lg"
            @keypress="filterInput"
          />
        </div>
        <Dropdown
          v-if="[0, 2].includes(tabIndex)"
          :model-value.sync="filter.order"
          name="komship"
          :options="tabIndex == 0 ? productOptions : orderOptions"
        />
      </div>
      <FilterButton
        v-if="tabIndex == 2"
        :daterange.sync="daterange"
        :checklist-label="checklistLabel"
        :checklist-data="checklistData"
        :checklist-model.sync="checklistModel"
        @resetFilter="resetFilter"
        @applyFilter="applyFilter"
        @closeFilter="closeFilter"
      />
    </div>
    <b-overlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <Product
        v-if="tabIndex == 0"
        :items="items"
        @onScroll="handleScroll"
      />
      <Warehouse
        v-if="tabIndex == 1"
        :items="items"
        @onScroll="handleScroll"
      />
      <Order
        v-if="tabIndex == 2"
        :status="filter.order"
        :items="items"
        @onScroll="handleScroll"
      />
    </b-overlay>
  </div>
</template>

<style scoped>
#search-komship {
  font-size: 14px;
}

.komsearch {
  border: 1px solid #E2E2E2;
  width: 100%;
}

.komsearch:has(#search-komship:focus) {
  border-color: rgb(249, 80, 49);
}

.search-drop {
  flex-direction: column;
  gap: .5rem;
  width: 100%;
}

@media screen and (min-width: 720px) {
  .komsearch {
    min-width: 324px;
  }

  .search-drop {
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    width: fit-content;
  }
}
</style>

<script>
import { newAxiosIns } from '@/libs/axios'
import { komshipDate } from '@/store/helpers'
import { today } from '../helper'
import { tableListProductKomship, defaultProductKomshipFilter } from '../config'
import Icon from './Icon.vue'
import Dropdown from './Dropdown.vue'
import FilterButton from './FilterButton.vue'
import Product from './Product.vue'
import Warehouse from './Warehouse.vue'
import Order from './Order.vue'

export default {
  components: {
    Icon,
    Dropdown,
    FilterButton,
    Product,
    Warehouse,
    Order,
  },
  props: {
    id: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      loading: false,
      tabIndex: 0,
      urls: ['/komship/api/v1/partner/product/', '/komship/api/v1/partner/warehouses/', '/komship/api/v1/admin/order/partner/'],
      placeholders: ['Nama produk', 'Nama gudang', 'Nomor order, resi'],
      komshipTabContents: ['Produk', 'Gudang', 'Order'],
      productOptions: [
        { value: 'terbaru', label: 'Produk Terbaru' },
        { value: 'terlama', label: 'Produk Terlama' },
        { value: 'terlaris', label: 'Produk Terlaris' },
        { value: 'belum laris', label: 'Produk Belum Laris' },
      ],
      orderOptions: [
        { value: '', label: 'Semua Order' },
        { value: 'Diajukan', label: 'Order Diajukan' },
        { value: 'Dipacking', label: 'Order Dipacking' },
        { value: 'Dikirim', label: 'Order Dikirim' },
        { value: 'Diterima', label: 'Order Diterima' },
        { value: 'Retur', label: 'Order Retur' },
      ],
      daterange: { startDate: komshipDate, endDate: new Date(new Date(today()).setHours(23, 59, 59)) },
      checklistData: {
        warehouses: [],
        products: [],
        paymentMethods: [{ text: 'COD', value: 'COD' }, { text: 'Transfer Bank', value: 'BANK TRANSFER' }],
        statuses: [{ text: 'Paket Hilang', value: 'Hilang' }, { text: 'Paket Rusak', value: 'Rusak' }],
      },
      checklistLabel: ['Gudang', 'Produk', 'Metode Pembayaran', 'Status'],
      checklistModel: Object.fromEntries(['warehouses', 'products', 'paymentMethods', 'statuses'].map(k => [k, []])),
      fields: tableListProductKomship,
      items: [],
      filter: defaultProductKomshipFilter[0],
      filterBeforeUpdate: {
        daterange: { startDate: komshipDate, endDate: new Date(new Date(today()).setHours(23, 59, 59)) },
        checklistModel: Object.fromEntries(['warehouses', 'products', 'paymentMethods', 'statuses'].map(k => [k, []])),
      },
      anyData: true,
    }
  },
  computed: {
    filterOrder() {
      return this.filter.order
    },
    filterStr() {
      return JSON.stringify({ ...this.filter, tabIndex: this.tabIndex })
    },
  },
  watch: {
    filterStr: {
      handler(newVal, oldVal) {
        const { tabIndex: newTabIndex, ...newValue } = JSON.parse(newVal)
        const { tabIndex: oldTabIndex, ...oldValue } = JSON.parse(oldVal)
        if (newValue.page === oldValue.page || newTabIndex !== oldTabIndex) {
          newValue.page = 0
          this.filterData(newValue)
          if (oldValue.page > 0 && newTabIndex === oldTabIndex) this.filter.page = 0
        }
      },
    },
    filterOrder: {
      handler(newVal, oldVal) {
        if (this.tabIndex === 2 && newVal !== oldVal) {
          if (newVal === 'Retur') {
            this.checklistData = this.reMove(this.checklistData, 2, 'returStatuses', [{ text: 'Dalam Perjalanan', value: 'sr-1' }, { text: 'Diterima', value: 'sr-2' }], 'statuses')
            this.checklistModel = this.reMove(this.checklistModel, 2, 'returStatuses', [], 'statuses')
            this.checklistLabel = ['Gudang', 'Produk', 'Status Retur', 'Metode Pembayaran']
          } else {
            this.checklistData = this.reMove(this.checklistData, 3, 'statuses', [{ text: 'Paket Hilang', value: 'Hilang' }, { text: 'Paket Rusak', value: 'Rusak' }], 'returStatuses')
            this.checklistModel = this.reMove(this.checklistModel, 3, 'statuses', [], 'returStatuses')
            this.checklistLabel = ['Gudang', 'Produk', 'Metode Pembayaran', 'Status']
          }
          this.filterBeforeUpdate.checklistModel = { ...this.checklistModel }
        }
      },
    },
  },
  async mounted() {
    await this.getFilterData()
    await this.initData()
  },
  methods: {
    async getFilterData() {
      try {
        const res = await newAxiosIns.get(`/komship/api/v1/admin/order/filter/${this.id}`)
        const { products, warehouse } = res.data.data
        this.checklistData.products = products ? products.map(p => ({ text: p.product_name, value: `p-${p.id}` })) : []
        this.checklistData.warehouses = warehouse ? warehouse.map(w => ({ text: w.name, value: `w-${w.id}` })) : []
      } catch (error) {
        this.$toast_error({ message: 'Gagal Mengambil Data Filter' })
        this.checklistData.products = []
        this.checklistData.warehouses = []
      }
    },
    async getData(index) {
      try {
        this.loading = true
        const params = { ...this.filter }
        if (this.tabIndex === 2) {
          if (params.order === 'Retur') params.status = []
          else params.status_retur = []

          if (params.warehouses.length === 0) delete params.warehouses
          if (params.products.length === 0) delete params.products
          if (params.payment_method.length === 0) delete params.payment_method
          if (params.status.length === 0) delete params.status
          if (params.status_retur.length === 0) delete params.status_retur
        }
        const res = await newAxiosIns.get(`${this.urls[index]}${this.id}`, { params })
        return res.data.data
      } catch (error) {
        this.$toast_error({ message: 'Gagal Mengambil Data' })
        return []
      } finally {
        this.loading = false
      }
    },
    async initData() {
      const data = await this.getData(this.tabIndex)
      if (!data) {
        this.anyData = false
        this.items = []
        return
      }
      this.items = [...data]
    },
    async getNextData() {
      if (!this.anyData) return
      this.filter.page += this.filter.limit
      const data = await this.getData(this.tabIndex)
      if (!data || data.length === 0) {
        this.anyData = false
        return
      }
      this.items = [...this.items, ...data]
    },
    async filterData(filter) {
      this.anyData = true
      this.filter = filter
      this.initData()
    },
    changeTab(index) {
      const beforeReset = {
        daterange: { ...this.daterange },
        checklistModel: { ...this.checklistModel },
      }
      const afterReset = {
        daterange: { startDate: komshipDate, endDate: new Date(new Date(today()).setHours(23, 59, 59)) },
        checklistModel: Object.fromEntries(['warehouses', 'products', 'paymentMethods', 'statuses'].map(k => [k, []])),
      }
      if (this.tabIndex === index && JSON.stringify(beforeReset) === JSON.stringify(afterReset)) return true
      this.daterange = afterReset.daterange
      this.checklistModel = afterReset.checklistModel
      this.filterBeforeUpdate = afterReset
      this.tabIndex = index
      this.filter = { ...defaultProductKomshipFilter[index] }
      return false
    },
    handleScroll(event) {
      if (this.items.length % this.filter.limit !== 0) return
      const { scrollTop, scrollHeight, clientHeight } = event.target
      if (scrollTop + clientHeight >= scrollHeight - 1 && !this.loading) this.getNextData()
    },
    resetFilter() {
      const paramUnchanged = this.changeTab(this.tabIndex)
      if (!paramUnchanged) this.filterData(this.filter)
    },
    applyFilter() {
      const afterUpdate = {
        daterange: { ...this.daterange },
        checklistModel: { ...this.checklistModel },
      }
      if (JSON.stringify(this.filterBeforeUpdate) === JSON.stringify(afterUpdate)) return
      this.filterBeforeUpdate = afterUpdate
      this.filter.start_date = this.daterange.startDate
      this.filter.end_date = this.daterange.endDate
      this.filter.warehouses = this.checklistModel.warehouses.map(c => +c.split('-')[1])
      this.filter.products = this.checklistModel.products.map(c => +c.split('-')[1])
      this.filter.payment_method = this.checklistModel.paymentMethods
      this.filter.status = this.checklistModel.statuses || []
      this.filter.status_retur = !this.checklistModel.returStatuses ? [] : this.checklistModel.returStatuses.map(c => +c.split('-')[1])
    },
    closeFilter() {
      this.daterange = { ...this.filterBeforeUpdate.daterange }
      this.checklistModel = { ...this.filterBeforeUpdate.checklistModel }
    },
    reMove(obj, indexToAdd, newKey, newValue, keyToRemove) {
      const data = obj
      if (keyToRemove) delete data[keyToRemove]
      const startKeys = Object.keys(data).slice(0, indexToAdd)
      const endKeys = Object.keys(data).slice(indexToAdd, Object.keys(data).length)
      const startValues = Object.values(data).slice(0, indexToAdd)
      const endValues = Object.values(data).slice(indexToAdd, Object.keys(data).length)
      const newKeys = [...startKeys, newKey, ...endKeys]
      const newValues = [...startValues, newValue, ...endValues]
      return Object.fromEntries(newKeys.map((k, i) => [k, newValues[i]]))
    },
    filterInput(e) {
      if (/[^A-Za-z0-9]/.test(e.key) || e.target.value.length >= 50) e.preventDefault()
    },
  },
}
</script>
