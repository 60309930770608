<template>
  <div
    class="position-relative"
    style="width: 100%; user-select: none;"
  >
    <button
      aria-label="Produk Terbaru"
      class="d-flex align-items-center justify-between gap-[14px] h-[40px] w-100 px-[12px] rounded-[8px] outline-none"
      style="border: 1px solid #E2E2E2;"
      @click="openDropdown"
    >
      <span class="text-[14px] text-[#C2C2C2]">{{ selectedLabel }}</span>
      <Icon name="Caret" />
    </button>
    <div
      id="dropitems"
      class="position-absolute top-[40px] w-100 bg-[#FFFFFF]"
      style="z-index: 100;"
      :style="{transform: `scaleY(${isOpen ? '1' : '0'})`}"
      @blur="closeDropdown"
    >
      <div
        style="box-shadow: 0 4px 10px 0 rbga(0, 0, 0, .15); border: 1px solid #E2E2E2;"
        class="mt-[7px] bg-[#FFFFFF] w-100 left-0 rounded-[6px] p-[4px] d-flex flex-column gap-[4px]"
      >
        <label
          v-for="(option, idx) in options"
          :key="idx"
          :for="`${name}-${idx}`"
          class="px-[8px] py-[4px] rounded-[8px] text-[#626262]"
        >
          {{ option.label }}
          <input
            :id="`${name}-${idx}`"
            class="d-none"
            type="radio"
            :name="name"
            :value="option.value"
            :disabled="modelValue === option.value"
            :checked="modelValue === option.value"
            @input="changeSelection($event, option)"
          >
        </label>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media screen and (min-width: 720px) {
  .position-relative {
    max-width: 202px;
  }
}
</style>

<style scoped>
.position-absolute {
  transform: scaleY(0);
  transition: transform .2s;
  transform-origin: top;
  will-change: transform;
}

label:has(input:checked) {
  background-color: #F8F8F8;
}
label:has(input:not(:disabled)) {
  cursor: pointer;
  transition: background-color .3s;
  &:hover {
    background-color: #F8F8F8;
  }
}
</style>

<script>
import Icon from './Icon.vue'

export default {
  components: {
    Icon,
  },
  emits: ['update:modelValue'],
  props: {
    name: {
      type: String,
      default: 'selector',
    },
    modelValue: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [
        { value: 'newest', label: 'Produk Terbaru' },
        { value: 'oldest', label: 'Produk Terlama' },
      ],
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    selectedLabel() {
      const selected = this.options.find(o => o.value === this.modelValue)
      return selected ? selected.label : 'No Default'
    },
  },
  methods: {
    changeSelection(e) {
      if (e.target.value === this.modelValue) return
      this.$emit('update:modelValue', e.target.value)
      setTimeout(() => { this.isOpen = false }, 100)
    },
    openDropdown() {
      this.isOpen = true
      const dropitems = document.getElementById('dropitems')
      dropitems.setAttribute('tabindex', '0')
      dropitems.focus()
    },
    closeDropdown(e) {
      const dropitems = document.getElementById('dropitems')
      if (dropitems.contains(e.relatedTarget)) {
        dropitems.setAttribute('tabindex', '0')
        dropitems.focus()
        return
      }
      setTimeout(() => { this.isOpen = false }, 100)
    },
  },
}
</script>
