<template>
  <div style="min-width: 52px; position: relative; user-select: none">
    <button
      class="h-[40px] w-[52px] rounded-[8px] filter-button outline-none"
      style="display: grid; place-items: center;"
      @click="openFilter"
    >
      <img
        width="24"
        height="24"
        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg"
        alt="filter-icon"
      >
    </button>
    <div
      class="position-absolute top-[40px] right-0 w-[250px]"
      style="z-index: 100;"
    >
      <div
        id="filtermenus"
        class="mt-[7px] bg-[#FFFFFF] d-flex flex-column"
        :style="{transform: `scaleY(${isOpen ? '1' : '0'})`}"
        @blur="closeFilter"
      >
        <span
          class="p-[10px] font-weight-bolder text-[#333333] text-[14px]"
          style="border-bottom: 1px solid #E2E2E2;"
        >Filter</span>
        <ul class="d-flex flex-column filter-menus mb-0">
          <li
            class="position-relative"
            @mouseover="openDate()"
            @mouseleave="closeDate()"
          >
            <span>Tanggal</span>
            <div
              class="position-absolute filter-calendar top-0 left-0 overflow-hidden"
              :class="{'filter-calendar-hover': dateOpen}"
            >
              <DateRangePicker
                ref="picker"
                :date-range="daterange"
                :locale-data="locale"
                :ranges="ranges"
                opens="right"
                auto-apply
                @update="updateDaterange"
              />
            </div>
          </li>
          <li
            v-for="(label, idx) in Object.keys(checklistData)"
            :key="idx"
            class="position-relative"
            :class="{'is-disabled': checklistData[label].length === 0 }"
          >
            <span>{{ checklistLabel[idx] }}</span>
            <div class="position-absolute left-0 top-0 filter-checks">
              <CheckGroup
                :name="label"
                :options="checklistData[label]"
                :model-value="checklistModel[label]"
                @update:modelValue="value => updateChecklistModel(value, label)"
              />
            </div>
          </li>
        </ul>
        <div
          style="border-top: 1px solid #E2E2E2;"
          class="py-[10px] px-[12px] d-flex align-items-center gap-[10px] filter-buttons"
        >
          <button
            id="resetbtn"
            class="bg-[#FFFFFF] text-[#F95031] outline-none"
            @click="resetFilter"
          >
            Reset
          </button>
          <button
            class="bg-[#F95031] text-[#FFFFFF] outline-none"
            @click="applyFilter"
          >
            Terapkan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#filtermenus {
  border: 1px solid #E2E2E2;
  box-shadow: 4px 4px 18px 0 rgba(0, 0, 0, .08);
  transform: scaleY(0);
  transition: transform .2s;
  transform-origin: top;
  will-change: transform;
}

.filter-button {
  background-color: hsl(9.3, 94.34%, 58.43%);
  transition: background-color .3s;
}

.filter-button:hover {
  background-color: hsl(9.3, 94.34%, 54%);
}

.filter-button:active {
  background-color: hsl(9.3, 94.34%, 52%);
}

.filter-menus > li > span {
  font-size: 14px;
  padding: 10px;
  display: block;
  background-color: #FFFFFF;
  color: #333333;
  transition: .2s;
  cursor: pointer;
}

.filter-menus > li.is-disabled > span {
  background-color: #F8F8F8;
  color: #828282;
  cursor: default;
}

.filter-menus > li:not(.is-disabled):hover > span {
  background-color: hsl(9.3, 94.34%, 58.43%);
  color: #FFFFFF;
}

.filter-menus > li:hover .filter-calendar-hover {
  transform: scaleX(1);
}

.filter-checks {
  translate: -100% 0;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform .2s;
  will-change: transform;
}

.filter-menus > li:not(.is-disabled):hover .filter-checks {
  transform: scaleX(1);
}

.filter-buttons > * {
  width: 100%;
  height: 24px;
  padding: 0 8px;
  border-radius: 4px;
  border: 1px solid hsl(9.3, 94.34%, 58.43%);
}

.filter-buttons > :first-child:hover {
  border-color: hsl(9.3, 94.34%, 54%);
  background-color: hsl(9.3, 94.34%, 95%);
}

.filter-buttons > :first-child:active {
  border-color: hsl(9.3, 94.34%, 52%);
  background-color: hsl(9.3, 94.34%, 90%);
}

.filter-buttons > :not(:first-child):hover {
  border-color: hsl(9.3, 94.34%, 54%);
  background-color: hsl(9.3, 94.34%, 54%);
}

.filter-buttons > :not(:first-child):active {
  border-color: hsl(9.3, 94.34%, 52%);
  background-color: hsl(9.3, 94.34%, 52%);
}

.filter-calendar {
  width: 694px;
  translate: -100% 0;
  height: 289px;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform .2s;
  will-change: transform;
}

.vue-daterange-picker {
  translate: -10px -38.5px;
}
</style>

<script>
import { LABELMONTH, LABELDAY } from '@/libs/filterDate'
import { komshipDate } from '@/store/helpers'
import DateRangePicker from 'vue2-daterange-picker'
import CheckGroup from './CheckGroup.vue'
import { monday, dateOne, today } from '../helper'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    DateRangePicker,
    CheckGroup,
  },
  props: {
    daterange: {
      type: Object,
      default: () => ({ startDate: komshipDate, endDate: today() }),
    },
    checklistLabel: {
      type: Array,
      default: () => [],
    },
    checklistData: {
      type: Object,
      default: () => ({
        warehouses: [],
        products: [],
        paymentMethods: [],
        statuses: [],
      }),
    },
    checklistModel: {
      type: Object,
      default: () => ({
        warehouses: [],
        products: [],
        paymentMethods: [],
        statuses: [],
      }),
    },
  },
  data() {
    return {
      locale: {
        format: 'dd mmm yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      ranges: {
        'Hari Ini': [today(), new Date(new Date(today()).setHours(23, 59, 59))],
        'Minggu Ini': [monday(), new Date(new Date(today()).setHours(23, 59, 59))],
        'Bulan Ini': [dateOne(), new Date(new Date(today()).setHours(23, 59, 59))],
        'Custom Date': [komshipDate, new Date(new Date(today()).setHours(23, 59, 59))],
      },
      isOpen: false,
      dateOpen: true,
    }
  },
  methods: {
    openDate() {
      this.$refs.picker.togglePicker(true)
    },
    closeDate() {
      this.$refs.picker.togglePicker(false)
    },
    updateDaterange(data) {
      this.dateOpen = false
      setTimeout(() => { this.dateOpen = true }, 200)
      this.$emit('update:daterange', data)
    },
    updateChecklistModel(data, label) {
      const newData = this.checklistModel
      newData[label] = data
      this.$emit('update:checklistModel', newData)
    },
    resetFilter() {
      this.$emit('resetFilter')
      setTimeout(() => { this.isOpen = false }, 100)
    },
    applyFilter() {
      this.$emit('applyFilter')
      setTimeout(() => { this.isOpen = false }, 100)
    },
    openFilter() {
      this.isOpen = true
      const filtermenus = document.getElementById('filtermenus')
      filtermenus.setAttribute('tabindex', '0')
      filtermenus.focus()
    },
    closeFilter(e) {
      const filtermenus = document.getElementById('filtermenus')
      if (filtermenus.contains(e.relatedTarget)) {
        filtermenus.setAttribute('tabindex', '0')
        filtermenus.focus()
        return
      }
      setTimeout(() => { this.isOpen = false }, 100)
      this.$emit('closeFilter')
    },
  },
}
</script>
