<template>
  <b-table
    id="table"
    :items="items"
    :fields="fields"
    empty-text="Tidak ada data yang ditampilkan."
    responsive
    show-empty
    sticky-header="500px"
    @scroll.native="onScroll"
  >
    <template #cell(order_date)="data">
      <div class="d-flex flex-column gap-[4px]">
        <span class="text-nowrap">{{ data.item.order_date ? convertToDateTimeString(data.item.order_date) : '-' }}</span>
        <div class="d-flex align-items-center gap-[4px]">
          <Icon
            name="Gudang"
            color="#C2C2C2"
            :size="16"
          />
          <span class="text-nowrap text-[12px]">{{ data.item.warehouse_name == '' ? '-' : data.item.warehouse_name }}</span>
        </div>
      </div>
    </template>
    <template #cell(customer)="data">
      <div class="d-flex flex-column">
        <span class="text-nowrap">{{ data.item.customer_name }}</span>
        <div class="d-flex align-items-center">
          <div
            class="h-[24px] px-[8px]"
            style="display: grid; place-items: center; min-width: fit-content;"
          >
            <img
              :src="data.item.shipment_image_path"
              :alt="data.item.shipping"
              height="14"
              style="max-height: 14px;"
            >
          </div>
          <span class="px-[6px] py-[2px]">{{ data.item.service_alias }}</span>
        </div>
      </div>
    </template>
    <template #cell(resi)="data">
      <div class="d-flex flex-column gap-[4px]">
        <div class="d-flex align-items-center gap-[4px]">
          <span class="text-[#333333]">{{ data.item.order_no }}</span>
          <feather-icon
            v-if="['dikirim', 'retur'].includes(status)"
            v-b-tooltip.hover.top="'Paket ini terkendala pengiriman'"
            icon="InfoIcon"
            size="26"
            color="#E31A1A"
          />
        </div>
        <button
          v-if="data.item.airway_bill"
          v-b-tooltip.blur.bottom="'Nomor resi telah disalin'"
          class="text-[#828282] px-[4px] bg-[#FEF2CD] d-flex align-items-center gap-[4px] outline-none rounded-[6px]"
          style="width: fit-content"
          aria-label="resi-button"
          @click="copyResi(data.item.airway_bill)"
        >
          <span>{{ data.item.airway_bill }}</span>
          <Icon name="Copy" />
        </button>
        <span v-else>-</span>
      </div>
    </template>
    <template #cell(product)="data">
      <div class="d-flex flex-column gap-[8px]">
        <div
          v-for="(product, idx) in data.item.products"
          :key="idx"
          class="d-flex align-items-center gap-[4px]"
        >
          <div
            class="w-[24px] h-[24px] rounded-circle overflow-hidden bg-[#F8F8F8]"
            style="display: grid; place-items: center;"
          >
            <feather-icon
              v-if="product.product_image_path == ''"
              icon="ImageIcon"
              size="16"
              color="#333333"
            />
            <img
              v-else
              :src="product.product_image_path"
              :alt="product.product_name"
            >
          </div>
          <span class="text-nowrap">{{ product.product_name || '-' }}</span>
        </div>
      </div>
    </template>
    <template #cell(customer_address)="data">
      <span>{{ data.item.address }}</span>
    </template>
    <template #cell(receive_date)="data">
      <span class="text-nowrap">{{ data.item.received_date ? convertToDateTime(data.item.received_date) : '-' }}</span>
    </template>
    <template #cell(amount)="data">
      <div class="d-flex flex-column">
        <span class="text-nowrap">{{ IDR(data.item.grand_total) }}</span>
        <mark
          class="py-[2px] px-[4px] rounded-[4px] text-[10px] text-nowrap"
          style="width: fit-content;"
          :class="data.item.payment_method == 'COD' ? 'text-[#34A770] bg-[#DCF3EB]' : 'text-[#D19D04] bg-[#FEF2CD]'"
        >{{ data.item.payment_method }}</mark>
      </div>
    </template>
    <template #cell(status)="data">
      <mark
        class="py-[2px] px-[4px] rounded-[4px] text-nowrap"
        :style="statusColor[data.item.order_status]"
      >
        {{ data.item.order_status === 'Batal' ? 'Dibatalkan' : 'Order ' + data.item.order_status }}
      </mark>
    </template>
    <template #cell(status_retur)="data">
      <mark
        v-if="data.item.is_retur > 0"
        class="py-[2px] px-[4px] rounded-[4px] text-nowrap"
        :style="returColor[data.item.is_retur - 1]"
      >
        {{ data.item.is_retur == 1 ? 'Perjalanan Pulang' : 'Diterima' }}
      </mark>
      <span v-else>-</span>
    </template>
    <template #cell(action)>
      <button
        class="outline-none text-[#08A0F7] text-nowrap cursor-pointer"
        style="text-decoration: underline;"
      >
        Lihat Detail
      </button>
    </template>
  </b-table>
</template>

<script>
import { IDR } from '@/libs/currency'
import { tableListOrderKomship } from '../config'
import { convertToDateTimeString, convertToDateTime } from '../helper'
import Icon from './Icon.vue'

export default {
  components: {
    Icon,
  },
  props: {
    items: {
      type: Array,
      default: null,
    },
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      IDR,
      statusColor: {
        Diajukan: { color: '#08A0F7', backgroundColor: '#DFF3FF' },
        Diterima: { color: '#34A770', backgroundColor: '#DCF3EB' },
        Dikirim: { color: '#D19D04', backgroundColor: '#FEF2CD' },
        Retur: { color: '#E31A1A', backgroundColor: '#FFEDED' },
        Dipacking: { color: '#D19D04', backgroundColor: '#FEF2CD' },
        Rusak: { color: '#E31A1A', backgroundColor: '#FFEDED' },
        Hilang: { color: '#E31A1A', backgroundColor: '#FFEDED' },
        Batal: { color: '#828282', backgroundColor: '#F8F8F8' },
      },
      returColor: [
        { color: '#E31A1A', backgroundColor: '#FFEDED' },
        { color: '#34A770', backgroundColor: '#DCF3EB' },
      ],
      fieldsByStatus: {
        semua: ['order_date', 'customer', 'resi', 'product', 'amount', 'status', 'action'],
        retur: ['order_date', 'customer', 'resi', 'product', 'amount', 'status_retur', 'action'],
        diproses: ['order_date', 'customer', 'resi', 'product', 'customer_address', 'amount', 'action'],
        diterima: ['order_date', 'customer', 'resi', 'product', 'receive_date', 'amount', 'action'],
      },
      convertToDateTimeString,
      convertToDateTime,
    }
  },
  computed: {
    fields() {
      if (['Diajukan', 'Dipacking', 'Dikirim'].includes(this.status)) {
        return tableListOrderKomship.filter(f => this.fieldsByStatus.diproses.includes(f.key))
      } if (this.status === '') {
        return tableListOrderKomship.filter(f => this.fieldsByStatus.semua.includes(f.key))
      } if (this.status === 'Retur') {
        return tableListOrderKomship.filter(f => this.fieldsByStatus.retur.includes(f.key))
      }
      return tableListOrderKomship.filter(f => this.fieldsByStatus.diterima.includes(f.key))
    },
  },
  methods: {
    async copyResi(resi) {
      await navigator.clipboard.writeText(resi)
    },
    onScroll(event) {
      this.$emit('onScroll', event)
    },
  },
}
</script>
