<template>
  <div class="info-detail-main">
    <b-overlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <B-card>
        <BRow class="items-center">
          <div
            class="d-flex"
            style="align-items: center;"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
              alt="Komerce"
              class="cursor-pointer mx-1"
              style="width: 30px;"
              @click="$router.push('/information')"
            >
            <strong class="text-black text-bold text-2xl">Detail Partner</strong>
          </div>
        </BRow>
        <BRow class="mt-[16px] p-[16px] bg-[#f8f8f8] rounded">
          <b-tabs
            v-model="tabIndex"
            content-class="mt-1"
            pills
            nav-class="d-flex align-items-center gap-[16px]"
            active-nav-item-class="shadow-none border-none font-weight-bolder rounded-pill"
            style="width: 100% !important;"
          >
            <b-tab
              title="General"
              lazy
              title-item-class="border border-[#E2E2E2] rounded-pill bg-[#ffffffcc]"
              :active="tabIndex == 0"
            >
              <General
                :user-data="details"
                :same-accounts="sameAcc"
              />
            </b-tab>
            <b-tab
              title="Komship"
              lazy
              title-item-class="border border-[#E2E2E2] rounded-pill bg-[#ffffffcc]"
              :active="tabIndex == 1"
            >
              <Komship :id="details.partner_id" />
            </b-tab>
          </b-tabs>
        </BRow>
      </B-card>
    </b-overlay>
  </div>
</template>

<style scoped>
.info-detail-main >>> .nav-pills .nav-link.active {
 background-color: rgba(249, 80, 49, 0.8) !important;
 border-color: transparent !important;
}
</style>

<script>
import {
  BRow,
  BCard,
} from 'bootstrap-vue'
import { newAxiosIns } from '@/libs/axios'
import General from './general/General.vue'
import Komship from './komship/Komship.vue'

export default {
  components: {
    BCard,
    BRow,
    General,
    Komship,
  },
  data() {
    return {
      loading: false,
      tabIndex: 0,
      details: {},
      sameAcc: {},
    }
  },
  mounted() {
    this.getDetails()
    this.getSameAcc()
  },
  methods: {
    async getDetails() {
      try {
        this.loading = true
        const res = await newAxiosIns.get(`/auth/api/v1/admin/partner/information/user/${this.$route.params.id}`)
        this.details = res.data.data
      } catch (error) {
        this.$toast_error({ message: 'Gagal Mengambil Detail Partner' })
      } finally {
        this.loading = false
      }
    },
    async getSameAcc() {
      try {
        this.loading = true
        const res = await newAxiosIns.get(`/auth/api/v1/admin/user/${this.$route.params.id}/same-account`)
        this.sameAcc = res.data.data
      } catch (error) {
        this.$toast_error({ message: 'Gagal Mengambil Data Kesamaan Akun' })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
