<template>
  <div
    style="border: 1px solid #e2e2e2"
    class="bg-[#FFFFFF] px-[1.5rem] rounded-[8px] h-100"
  >
    <h5
      style="border-bottom: 1px dashed #E2E2E2;"
      class="text-[#333333] font-weight-bolder text-[1.25rem] py-[12px]"
    >Informasi Dasar</h5>
    <div class="d-flex flex-column gap-[1rem] py-[1rem] text-[#333333]">
      <div class="d-flex gap-[2rem]">
        <p
          style="min-width: 135px;"
          class="mb-0 h-[2rem]"
        >
          Nama Lengkap
        </p>
        <div class="d-flex align-items-center gap-[12px]">
          <b>{{ name == '' ? '-' : name }}</b>
          <span
            id="partner-info-general-verif-ktp"
            style="display: grid; place-items: center;"
            class="w-[32px] h-[32px] rounded-circle cursor-pointer"
            :style="{backgroundColor: verificationData.bgcolor}"
          >
            <img
              :src="verificationData.icon"
              width="20"
              height="20"
              alt="status-icon"
            >
          </span>
          <b-tooltip
            target="partner-info-general-verif-ktp"
            triggers="hover"
            :noninteractive="[0, 2].includes(status)"
          >
            <router-link
              class="text-[#7B7B7B]"
              :to="[1, 3].includes(status) ? `/verification-ktp/${ktpId}/file/${partnerId}` : ''"
            >
              {{ verificationData.text }}
            </router-link>
          </b-tooltip>
        </div>
      </div>
      <div class="d-flex gap-[2rem]">
        <p
          style="min-width: 135px;"
          class="mb-0 h-[2rem]"
        >
          Username
        </p>
        <b>{{ username == '' ? '-' : username }}</b>
      </div>
      <div class="d-flex gap-[2rem]">
        <p
          style="min-width: 135px;"
          class="mb-0 h-[2rem]"
        >
          Jenis Kelamin
        </p>
        <b>{{ gender == 0 ? 'Perempuan' : 'Laki-laki' }}</b>
      </div>
      <div class="d-flex gap-[2rem]">
        <p
          style="min-width: 135px;"
          class="mb-0 h-[2rem]"
        >
          Nomor HP
        </p>
        <div
          style="width: calc(100% - 170px);"
          class="d-flex align-items-center gap-[12px]"
        >
          <b
            v-b-tooltip.hover.top="phone"
            class="text-truncate"
            style="max-width: calc(100% - 36px);"
          >{{ phone == '' ? '-' : phone }}</b>
          <!-- <button
            aria-label="edit-phone-number"
            style="border: none; outline: none; min-width: 36px;"
          >
            <img
              src="../assets/edit.svg"
              width="24"
              height="24"
              alt="edit-icon"
            >
          </button> -->
        </div>
      </div>
      <div class="d-flex gap-[2rem]">
        <p
          style="min-width: 135px;"
          class="mb-0 h-[2rem]"
        >
          Email
        </p>
        <div
          style="width: calc(100% - 170px);"
          class="d-flex align-items-center gap-[12px]"
        >
          <b
            v-b-tooltip.hover.top="email"
            class="text-truncate"
            style="max-width: calc(100% - 36px);"
          >{{ email == '' ? '-' : email }}</b>
          <!-- <button
            aria-label="edit-email"
            style="border: none; outline: none; min-width: 36px;"
          >
            <img
              src="../assets/edit.svg"
              width="24"
              height="24"
              alt="edit-icon"
            >
          </button> -->
        </div>
      </div>
      <div class="d-flex gap-[2rem]">
        <p
          style="min-width: 135px;"
          class="mb-0 h-[2rem]"
        >
          Alamat Lengkap
        </p>
        <b>{{ address == '' ? '-' : address }}</b>
      </div>
    </div>
  </div>
</template>

<script>
import rejected from '../assets/rejected.svg'
import verified from '../assets/verified.svg'
import inprogress from '../assets/inprogress.svg'
import unverified from '../assets/unverified.svg'

export default {
  props: {
    name: {
      type: String,
      default: '-',
    },
    username: {
      type: String,
      default: '-',
    },
    status: {
      type: Number,
      default: 0,
    },
    gender: {
      type: Number,
      default: 0,
    },
    phone: {
      type: String,
      default: '-',
    },
    email: {
      type: String,
      default: '-',
    },
    address: {
      type: String,
      default: '-',
    },
    partnerId: {
      type: Number,
      default: 1,
    },
    ktpId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    verificationData() {
      switch (this.status) {
        case 3:
          return { text: 'Pengajuan Verifikasi Ditolak', bgcolor: '#FFEDED', icon: rejected }
        case 2:
          return { text: 'Terverifikasi', bgcolor: '#DCF3EB', icon: verified }
        case 1:
          return { text: 'Sedang Proses Pengajuan Verifikasi', bgcolor: '#EEf3DC', icon: inprogress }
        default:
          return { text: 'Belum Mengajukan Verifikasi', bgcolor: '#E6E6E6', icon: unverified }
      }
    },
  },
}
</script>
