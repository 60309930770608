<template>
  <b-table
    id="table"
    :items="items"
    :fields="fields"
    empty-text="Tidak ada data yang ditampilkan."
    responsive
    show-empty
    sticky-header="500px"
    @scroll.native="onScroll"
  >
    <template #cell(name)="data">
      <div class="d-flex flex-column gap-[4px]">
        <div class="d-flex align-items-center gap-[6px]">
          <Icon
            name="Gudang"
            color="#C2C2C2"
            :size="20"
          />
          <span class="text-nowrap">{{ data.item.name || '-' }}</span>
        </div>
        <span class="text-nowrap text-[12px]">{{ data.item.created_at ? convertToDateString(data.item.created_at) : '-' }}</span>
      </div>
    </template>
    <template #cell(address)="data">
      <div>{{ data.item.detail_address || '-' }}</div>
    </template>
    <template #cell(zip_code)="data">
      <div>{{ data.item.zip_code || '-' }}</div>
    </template>
    <template #cell(pic_name)="data">
      <div class="text-nowrap">
        {{ data.item.pic_name || '-' }}
      </div>
    </template>
    <template #cell(contact)="data">
      <div class="d-flex align-items-center gap-[4px]">
        <span>{{ data.item.pic_phone || '-' }}</span>
        <button
          class="outline-none"
          style="min-width: 16px;"
        >
          <img
            v-if="data.item.pic_phone"
            src="../assets/whatsapp.svg"
            alt="whatsapp-icon"
            width="16"
            height="16"
          >
        </button>
      </div>
    </template>
  </b-table>
</template>

<script>
import { tableListWarehouseKomship } from '../config'
import { convertToDateString } from '../helper'
import Icon from './Icon.vue'

export default {
  components: {
    Icon,
  },
  props: {
    items: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      fields: tableListWarehouseKomship,
      convertToDateString,
    }
  },
  methods: {
    onScroll(event) {
      this.$emit('onScroll', event)
    },
  },
}
</script>
