<template>
  <div>
    <svg
      v-if="name === 'Produk'"
      :width="size"
      :height="Math.ceil(17/16 * size)"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.11328 5.45996L7.99994 8.86662L13.8466 5.47994"
        :stroke="color"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 14.9065V8.85986"
        :stroke="color"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.61991 2.1535L3.05992 4.13352C2.25325 4.58018 1.59326 5.70017 1.59326 6.62017V10.3869C1.59326 11.3069 2.25325 12.4268 3.05992 12.8735L6.61991 14.8535C7.37991 15.2735 8.62658 15.2735 9.38658 14.8535L12.9466 12.8735C13.7533 12.4268 14.4132 11.3069 14.4132 10.3869V6.62017C14.4132 5.70017 13.7533 4.58018 12.9466 4.13352L9.38658 2.1535C8.61991 1.72683 7.37991 1.72683 6.61991 2.1535Z"
        :stroke="color"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3333 9.32674V6.88676L5.00659 3.2334"
        :stroke="color"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-if="name == 'Gudang'"
      :width="size"
      :height="Math.ceil(17/16 * size)"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33325 15.1665H14.6666"
        :stroke="color"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.96655 15.1665L1.99989 7.1465C1.99989 6.73983 2.19322 6.3532 2.51322 6.09987L7.17989 2.46652C7.65989 2.09319 8.33322 2.09319 8.81989 2.46652L13.4866 6.09319C13.8132 6.34653 13.9999 6.73316 13.9999 7.1465V15.1665"
        :stroke="color"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M10.3334 7.8335H5.66675C5.11341 7.8335 4.66675 8.28016 4.66675 8.8335V15.1668H11.3334V8.8335C11.3334 8.28016 10.8867 7.8335 10.3334 7.8335Z"
        :stroke="color"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.66675 11.3335V12.3335"
        :stroke="color"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 5.5H9"
        :stroke="color"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-if="name == 'Order'"
      :width="size"
      :height="Math.ceil(17/16 * size)"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.48659 13.6335C5.03325 13.0468 5.86659 13.0935 6.34659 13.7335L7.01992 14.6335C7.55992 15.3468 8.43325 15.3468 8.97325 14.6335L9.64659 13.7335C10.1266 13.0935 10.9599 13.0468 11.5066 13.6335C12.6933 14.9002 13.6599 14.4802 13.6599 12.7068V5.1935C13.6666 2.50683 13.0399 1.8335 10.5199 1.8335H5.47992C2.95992 1.8335 2.33325 2.50683 2.33325 5.1935V12.7002C2.33325 14.4802 3.30659 14.8935 4.48659 13.6335Z"
        :stroke="color"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.33325 5.1665H10.6666"
        :stroke="color"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 7.8335H10"
        :stroke="color"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-if="name == 'Search'"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
        stroke="#626262"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 22L20 20"
        stroke="#626262"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-if="name == 'Caret'"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.92 8.9502L13.4 15.4702C12.63 16.2402 11.37 16.2402 10.6 15.4702L4.07996 8.9502"
        stroke="#333333"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-if="name == 'Copy'"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.39992 15.6663H4.59992C1.99325 15.6663 0.833252 14.5063 0.833252 11.8997V9.09967C0.833252 6.49301 1.99325 5.33301 4.59992 5.33301H7.39992C10.0066 5.33301 11.1666 6.49301 11.1666 9.09967V11.8997C11.1666 14.5063 10.0066 15.6663 7.39992 15.6663ZM4.59992 6.33301C2.53325 6.33301 1.83325 7.03301 1.83325 9.09967V11.8997C1.83325 13.9663 2.53325 14.6663 4.59992 14.6663H7.39992C9.46659 14.6663 10.1666 13.9663 10.1666 11.8997V9.09967C10.1666 7.03301 9.46659 6.33301 7.39992 6.33301H4.59992Z"
        fill="#FBBC05"
      />
      <path
        d="M11.3999 11.6663H10.6666C10.3933 11.6663 10.1666 11.4397 10.1666 11.1663V9.09967C10.1666 7.03301 9.46659 6.33301 7.39992 6.33301H5.33325C5.05992 6.33301 4.83325 6.10634 4.83325 5.83301V5.09967C4.83325 2.49301 5.99325 1.33301 8.59992 1.33301H11.3999C14.0066 1.33301 15.1666 2.49301 15.1666 5.09967V7.89967C15.1666 10.5063 14.0066 11.6663 11.3999 11.6663ZM11.1666 10.6663H11.3999C13.4666 10.6663 14.1666 9.96634 14.1666 7.89967V5.09967C14.1666 3.03301 13.4666 2.33301 11.3999 2.33301H8.59992C6.53325 2.33301 5.83325 3.03301 5.83325 5.09967V5.33301H7.39992C10.0066 5.33301 11.1666 6.49301 11.1666 9.09967V10.6663Z"
        fill="#FBBC05"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'Produk',
    },
    color: {
      type: String,
      default: '#828282',
    },
    size: {
      type: Number,
      default: 16,
    },
  },
}
</script>
