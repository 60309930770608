<template>
  <b-table
    id="table"
    :items="datas"
    :fields="fields"
    empty-text="Tidak ada data yang ditampilkan."
    responsive
    show-empty
    sticky-header="500px"
    @scroll.native="onScroll"
  >
    <template #cell(name)="data">
      <div class="flex text-left d-flex align-items-center gap-4">
        <div
          class="w-[32px] h-[32px] rounded-circle overflow-hidden bg-[#F8F8F8]"
          style="display: grid; place-items: center;"
        >
          <img
            v-if="data.item.product_image_path"
            :src="data.item.product_image_path"
            :alt="data.item.product_name"
            style="width: 100%; height: 100%; object-fit: cover; object-position: center;"
          >
          <feather-icon
            v-else
            icon="ImageIcon"
            size="24"
            color="#333333"
          />
        </div>
        <div class="d-flex flex-column">
          <span class="text-nowrap">{{ data.item.product_name }}</span>
          <strong class="font-weight-normal text-nowrap text-[#FF6A3A] text-[12px]">SKU: {{ data.item.product_sku || '-' }}</strong>
        </div>
      </div>
    </template>
    <template #cell(variant)="data">
      <div class="d-flex flex-column">
        <span
          v-for="(v, idx) in data.item.product_variants"
          :key="idx"
          class="text-nowrap"
        >{{ v.variant || '-' }}</span>
      </div>
    </template>
    <template #cell(weight)="data">
      <div class="d-flex flex-column">
        <span
          v-for="(_, idx) in data.item.product_variants"
          :key="idx"
        >{{ data.item.product_weight }}</span>
      </div>
    </template>
    <template #cell(volume)="data">
      <div class="d-flex flex-column">
        <span
          v-for="(_, idx) in data.item.product_variants"
          :key="idx"
          class="text-nowrap"
        >p: {{ data.item.product_length }}, l: {{ data.item.product_width }}, t: {{ data.item.product_height }}</span>
      </div>
    </template>
    <template #cell(price)="data">
      <div class="d-flex flex-column">
        <span
          v-for="(v, idx) in data.item.product_variants"
          :key="idx"
          class="text-nowrap"
        >{{ IDR(v.price) }}</span>
      </div>
    </template>
    <template #cell(stock)="data">
      <div class="d-flex flex-column">
        <span
          v-for="(v, idx) in data.item.product_variants"
          :key="idx"
        >{{ v.stock }}</span>
      </div>
    </template>
    <template #cell(sold)="data">
      <div class="d-flex flex-column">
        <span
          v-for="(v, idx) in data.item.product_variants"
          :key="idx"
        >{{ v.sold }}</span>
      </div>
    </template>
  </b-table>
</template>

<script>
import { IDR } from '@/libs/currency'
import { tableListProductKomship } from '../config'

export default {
  props: {
    items: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      IDR,
      fields: tableListProductKomship,
    }
  },
  computed: {
    datas() {
      return this.items.map(item => {
        const result = item
        if (!item.product_variants) {
          result.product_variants = [{
            sold: item.sold,
            price: item.product_price,
            variant: null,
            stock: item.product_stock,
          }]
        }
        return result
      })
    },
  },
  methods: {
    onScroll(event) {
      this.$emit('onScroll', event)
    },
  },
}
</script>
