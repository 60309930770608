<template>
  <div
    class="mr-[12px] bg-[#FFFFFF] rounded-[6px] p-[4px]"
    style="border: 1px solid #E2E2E2; box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .15);"
  >
    <div class="px-[8px] pt-[4px] mb-[8px] cursor-pointer">
      <b-form-checkbox
        :id="`all-${name}`"
        :checked="isAllChecked"
        :indeterminate="indeterminate"
        :name="`all-${name}`"
        @change="onSelectAll"
      >
        Semua
      </b-form-checkbox>
    </div>
    <b-form-checkbox-group
      :id="`${name}-group`"
      :checked="localValue"
      :name="`${name}-group`"
      class="pt-[4px]"
      style="border-top: 1px solid #E2E2E2; max-height: 174px; overflow-y: auto;"
      stacked
      @change="onInput"
    >
      <div
        v-for="(item, idx) in options"
        :key="idx"
        class="px-[8px] py-[4px] cursor-pointer"
      >
        <b-form-checkbox
          :id="item.value"
          :value="item.value"
          :name="name"
        >
          {{ item.text }}
        </b-form-checkbox>
      </div>
    </b-form-checkbox-group>
  </div>
</template>

<style scoped>
.cursor-pointer >>> label {
  cursor: pointer;
  width: 100%;
}
</style>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'check',
    },
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localValue: this.modelValue,
      isAllChecked: this.modelValue.length > 0 && this.modelValue.length === this.options.length,
      indeterminate: this.modelValue.length > 0 && this.modelValue.length < this.options.length,
    }
  },
  watch: {
    modelValue(value) {
      this.localValue = value
      this.isAllChecked = this.modelValue.length > 0 && this.modelValue.length === this.options.length
      this.indeterminate = this.modelValue.length > 0 && this.modelValue.length < this.options.length
    },
  },
  methods: {
    onInput(e) {
      this.$emit('update:modelValue', e)
      this.indeterminate = e.length > 0 && e.length < this.options.length
      this.isAllChecked = e.length > 0 && e.length === this.options.length
    },
    onSelectAll(isChecked) {
      let allValues = []
      if (this.indeterminate || isChecked) allValues = this.options.map(o => o.value)
      this.$emit('update:modelValue', allValues)
      this.indeterminate = false
      this.isAllChecked = isChecked
    },
  },
}
</script>
