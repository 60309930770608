<template>
  <div
    style="border: 1px solid #e2e2e2; min-height: fit-content;"
    class="bg-[#FFFFFF] rounded-[8px] px-[1.5rem] py-[1rem]"
  >
    <div class="d-flex flex-column gap-[1.5rem]">
      <div
        class="pb-[1.5rem]"
        style="border-bottom: 1px dashed #E2E2E2;"
      >
        <h5 class="font-weight-bolder text-[1rem] pb-[1rem] text-[#333333]">
          Ringkasan Info
        </h5>
        <div
          class="text-[#333333] text-[14px]"
        >
          <div class="d-flex gap-[12px] mb-[12px]">
            <span class="w-[120px]">
              Nama Bisnis
            </span>
            <span>:</span>
            <span>{{ ["", null].includes(brandName) ? "-" : brandName }}</span>
          </div>
          <div class="d-flex gap-[12px]">
            <span class="w-[120px]">
              Saldo Terupdate
            </span>
            <span>:</span>
            <span class="text-[#F95031]">{{ IDR(kmpoin) }}</span>
          </div>
        </div>
      </div>
      <div
        class="pb-[1.5rem]"
        style="border-bottom: 1px dashed #E2E2E2;"
      >
        <h5 class="font-weight-bolder text-[1rem] pb-[1rem] text-[#333333]">
          Registrasi Produk
        </h5>
        <div class="text-[#333333] d-flex flex-wrap gap-[8px] text-[#333333]">
          <span v-if="!products">Belum ada produk terdaftar</span>
          <div
            v-for="(product, idx) in products"
            v-else
            :key="idx"
            class="px-[8px] py-[4px] rounded-[8px] bg-[#F8F8F8] d-flex aliproducts-center gap-[8px]"
          >
            <div
              class="w-[1.5rem] h-[1.5rem] rounded-circle bg-[#FFFFFF]"
              style="display: grid; place-items: center; border: 1px solid #e2e2e2"
            >
              <img
                :src="product.url_image"
                :alt="product.product_name"
                width="16"
                height="16"
              >
            </div>
            <!-- <span>15 Oktober 2021</span> -->
          </div>
        </div>
      </div>
      <div>
        <h5 class="font-weight-bolder text-[1rem] pb-[1rem] text-[#333333]">
          Kesamaan Akun
        </h5>
        <div class="text-[#333333] d-flex flex-column gap-[1rem]">
          <div
            v-for="(accounts, aidx) in Object.keys(sameAccounts)"
            :key="aidx"
          >
            <p>{{ accounts }} :</p>
            <ul style="height: 100%; max-height: 160px; overflow-y: auto; margin: 0;">
              <li
                v-if="!sameAccounts[accounts]"
                style="list-style-type: disc;"
                class="ml-[36px]"
              >
                (Belum ada kesamaan dengan akun lainnya)
              </li>
              <div
                v-for="(account, idx) in sameAccounts[accounts]"
                v-else
                :key="idx"
                :class="sameAccounts[accounts].length > 2 ? 'pr-[8px]' : ''"
              >
                <li
                  class="mb-[10px] ml-[36px]"
                  style="list-style-type: disc;"
                >
                  <p
                    v-b-tooltip.hover.top="account.email"
                    style="max-width: calc(100% - 36px)"
                    class="text-truncate"
                  >
                    {{ account.email }}
                  </p>
                </li>
                <div class="d-flex align-items-center gap-[12px] mb-[14px] pl-[12px]">
                  <img
                    width="24"
                    height="24"
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/wallet.svg"
                    alt="wallet-icon"
                  >
                  <span
                    style="border: 1px solid #e2e2e2;"
                    class="px-[14px] py-[10px] rounded-[8px] w-100"
                  >{{ IDR(account.kmpoin) }}</span>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media screen and (min-width: 1280px) {
  .text-truncate {
    max-width: 280px;
  }
}
</style>

<script>
import { IDR } from '@/libs/currency'

export default {
  props: {
    brandName: {
      type: String,
      default: null,
    },
    kmpoin: {
      type: Number,
      default: 0,
    },
    products: {
      type: Array,
      default: null,
    },
    sameAccounts: {
      type: Object,
      default: () => ({
        'Nama Pemilik Bank': null,
        'Nomor HP': null,
        'Nomor HP Bisnis': null,
      }),
    },
  },
  data() {
    return {
      IDR,
    }
  },
}
</script>
