<template>
  <div class="general d-flex flex-column gap-[1rem]">
    <div class="d-flex flex-column gap-[1rem] w-100">
      <Information
        :name="uData.full_name"
        :username="uData.user_name"
        :status="uData.verification_ktp_status"
        :gender="uData.gender"
        :phone="uData.phone_number"
        :email="uData.email"
        :address="uData.address"
        :partner-id="uData.partner_id"
        :ktp-id="uData.partner_ktp_verif_id"
      />
      <!-- <Amount /> -->
    </div>
    <div class="d-flex flex-column w-100">
      <Summary
        :brand-name="uData.brand_name"
        :kmpoin="uData.kmpoin"
        :products="uData.products"
        :same-accounts="cleanSameAccounts"
      />
    </div>
  </div>
</template>

<style scoped>
@media screen and (min-width: 1280px) {
  .general {
    flex-direction: row !important;
    justify-content: space-between !important;
  }
  .general > *:first-child {
    width: calc(100% - 511px) !important;
  }
  .general > *:nth-child(2) {
    width: 510px !important;
  }
}
</style>

<script>
import Information from './Information.vue'
import Summary from './Summary.vue'

export default {
  components: {
    Information,
    // Amount,
    Summary,
  },
  props: {
    userData: {
      type: Object,
      default: () => ({
        full_name: '-',
        user_name: '-',
        gender: 0,
        phone_number: '-',
        email: '-',
        address: '-',
        products: null,
        kmpoin: 0,
        brand_name: null,
        verification_ktp_status: 0,
        partner_id: 1,
        partner_ktp_verif_id: null,
      }),
    },
    sameAccounts: {
      type: Object,
      default: () => ({
        same_bank_account: null,
        same_phone: null,
        same_business_phone: null,
      }),
    },
  },
  computed: {
    cleanSameAccounts() {
      return Object.fromEntries(['Nama Pemilik Bank', 'Nomor HP', 'Nomor HP Bisnis'].map((i, idx) => [i, Object.values(this.sameAccounts)[idx]]))
    },
    uData() {
      return this.userData
    },
  },
}
</script>
