export const convertToDateString = isoStr => {
  const date = new Date(isoStr).toDateString().split(' ')
  return `${+date[2]} ${date[1]} ${date[3]}`
}

export const convertToDateTimeString = isoStr => {
  const date = new Date(isoStr).toDateString().split(' ')
  const time = isoStr.split('T')[1].substring(0, 5)
  return `${+date[2]} ${date[1]} ${date[3]} - ${time}`
}

export const convertToDateTime = isoStr => {
  const date = new Date(isoStr).toLocaleDateString().replaceAll('/', '-')
  const time = isoStr.split('T')[1].substring(0, 5)
  return `${date} ${time}`
}

export const monday = () => {
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  const todayStr = new Date().toDateString().substring(0, 3)
  const idxOfToday = days.indexOf(todayStr)
  const mond = new Date().setDate(new Date().getDate() - idxOfToday)
  return new Date(new Date(mond).toISOString().split('T')[0])
}

export const dateOne = () => {
  const one = new Date().setDate(1)
  return new Date(new Date(one).toISOString().split('T')[0])
}

export const today = () => new Date(new Date().toISOString().split('T')[0])
